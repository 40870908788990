import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Styles/App.scss';
import HomePage from './Pages/HomePage/HomePage';
import ContactPage from './Pages/ContactPage/ContactPage';
import AboutPage from './Pages/AboutPage/AboutPage';
import GalleryPage from './Pages/GalleryPage/GalleryPage';
import StorePage from './Pages/StorePage/StorePage';
import AdminPage from './Pages/AdminPage/AdminPage';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import { useState } from 'react';

function App() {
  const [loaded, setLoaded] = useState(false);

  return (
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path='/' exact element={<HomePage setLoaded={setLoaded} loaded={loaded} />} />
            <Route path='/admin' element={<AdminPage />} />
            <Route path='/admin/:component' element={<AdminPage />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/gallery/:category' element={<GalleryPage />} />
            <Route path='/gallery' element={<GalleryPage />} />
            <Route path='/store' element={<StorePage />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
  );
}

export default App;
