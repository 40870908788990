import { Link } from 'react-router-dom';
import './Header.scss';
import { FiMenu } from 'react-icons/fi';
import { FaRegPaperPlane } from 'react-icons/fa';

const Header = ({ toggleOpen }) => {
    return (
        <header className='header'>
            <Link to='/'>
                <div className='header__logo'>
                    <h1>NDVR</h1>
                </div>
            </Link>

            <div className='header__mobile-nav' onClick={toggleOpen}>
                <FiMenu />
            </div>

            <div className='header__nav'>
                <Link to='/about' className='header__link'>ABOUT</Link>
                <Link to='/gallery' className='header__link'>WORK</Link>
                <Link to='/store' className='header__link'>SHOP</Link>
            </div>
            <Link to='/contact' className='header__button'>
                <p>GET IN TOUCH</p>
                <FaRegPaperPlane />
            </Link>
        </header>
    )

}

export default Header;