import { Link } from 'react-router-dom';
import './Sidebar.scss';
import { FaTimes, FaRegPaperPlane } from 'react-icons/fa'
import { useEffect } from 'react';

const Sidebar = ({ toggleOpen, dropdown }) => {
    
    useEffect(() => {
        if(dropdown) {
            let d = document.querySelector('.sidebar__container');
            d.style['top'] = 0;
            d.style['opacity'] = 1;

        } else {
            let d = document.querySelector('.sidebar__container');
            d.style['top'] = '-100%';
            d.style['opacity'] = 0;
        }
    }, [dropdown]);

    return (
        <div className='sidebar__container' onClick={toggleOpen}>
            <div className='sidebar__close'>
                <FaTimes />
            </div>
            <div className='sidebar__menu'>
                <Link to='/about' className='sidebar__link' onClick={toggleOpen}>ABOUT</Link>
                <Link to='/gallery' className='sidebar__link' onClick={toggleOpen}>WORK</Link>
                <Link to='/store' className='sidebar__link' onClick={toggleOpen}>SHOP</Link>
                <Link to='/contact' className='sidebar__button' onClick={toggleOpen}>
                    <p>GET IN TOUCH</p>
                    <FaRegPaperPlane />
                </Link>
            </div>
        </div>
    )

}

export default Sidebar;