import { useEffect, useState } from 'react';
import axios from 'axios';
import './ManageContacts.scss';
import { AiOutlineDelete } from 'react-icons/ai';

const { REACT_APP_BACKEND_URL } = process.env;

const ManageContacts = () => {
  const [contactList, setContactList] = useState([]);
  const [loadingContacts, setLoadingContacts] = useState(true);

  useEffect(() => {
    axios.get(`${REACT_APP_BACKEND_URL}contacts/`).then(res => {
      setContactList(res.data);
      setLoadingContacts(false);
    }).catch(err => console.log(err));

  }, [])

  const updateContacts = () => {
    axios.get(`${REACT_APP_BACKEND_URL}contacts/`).then(res => {
      setContactList(res.data);
    }).catch(err => console.log(err));
  }

  const handleDelete = (e) => {
    if (window.confirm('Are you sure you want to delete this contact?')) {
      axios.delete(`${REACT_APP_BACKEND_URL}contacts/${e.target.getAttribute('contact_id')}`).then(_res => {
        updateContacts();
      }).catch(err => console.log(err));
    }
  }
  
  return (
    <section className='contacts__container'>
      {loadingContacts ? (<div className="contacts__loading">
          <span className="loader"></span>
        </div>
        ) : (
        <div className='contacts-loaded__container'>
          <div className='contact-title__container'>
            <p className='contact-title contact-title__name'>NAME</p>
            <p className='contact-title contact-title__email'>EMAIL</p>
            <p className='contact-title contact-title__date'>CONTACT DATE</p>
            <p className='contact-title contact-title__delete'>DELETE</p>
          </div>
          {contactList.map((contact, index) => (
            <div key={contact.id} className='contact__container'>
              <p className='contact__name'>{contact.name}</p>
              <p className='contact__email'>{contact.email}</p>
              <p className='contact__date'>{contact.initDate.slice(0,10)}</p>
              <div className='contact__delete'>
                <AiOutlineDelete />
                <div className='contact__delete-overlay' contact_id={contact.id} index={index} onClick={handleDelete}></div>
              </div>  
            </div>
          ))}
        </div>)}
    </section>
  )
}

export default ManageContacts;