import { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Footer from '../../Components/Footer/Footer';
import './HomePage.scss';
import { IoEarthSharp } from 'react-icons/io5';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';

//images
import foodImg from '../../Assets/Images/food.webp';
import fitnessImg from '../../Assets/Images/fitness.webp';
import lifestyleImg from '../../Assets/Images/lifestyle.webp';
import productImg from '../../Assets/Images/product.webp';



const HomePage = ({ loaded, setLoaded }) => {
    const [dropdown, setDropdown] = useState(false);

    const toggleOpen = () => {
        setDropdown(!dropdown);
    }

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (loaded === false) {
            setLoading(true);
        }
        setTimeout(() => {
            setLoading(false);
            setLoaded(true);
        }, 2500);
    }, [loaded, setLoaded]);

    useEffect(() => {
        const hero = document.querySelector('.homepage__hero');
        const bgNumber = Math.ceil(Math.random() * 4);
        hero.id = `hero${bgNumber}`;
    }, []);

    return (<>
        {loading ? (
            <div className="loader-container">
                <div className="spinner">NDVR</div>
            </div>
        ) : (<></>)}
        <Sidebar dropdown={dropdown} toggleOpen={toggleOpen} />
        <Header toggleOpen={toggleOpen} />
        <section className='homepage__hero'>
            <div className='homepage__hero-overlay'></div>
            <div className='homepage__hero-tagline'>
                <div className='homepage__hero-location'>
                    <IoEarthSharp />
                    <p className='homepage__hero-text'>TORONTO, CA</p>
                </div>
                <div className='homepage__hero-tag'>
                    <p className='homepage__hero-text'>CREATING CAPTIVATING PHOTOS</p>
                </div>
            </div>
        </section>
        <section className='homepage__about'>
            <div className='homepage__about-title-container'>
                <h1 className='homepage__about-title'>I WANT TO MAKE YOU SAY, "DAA-MN."</h1>
                <div className='homepage__about-title-border'></div>
            </div>
            <div className='homepage__about-copy-container'>
                <p className='homepage__about-copy'>
                Hey there! I'm Justin, and I'm a multi-disciplinary photographer based out of Toronto, Canada. 
                </p>
                <p className='homepage__about-copy'>
                    I'm here to capture your achievements—your endeavors—so that 10 or 50 years down the road, you can look back and say, “Daa-mn. I did that.” 
                </p>
            </div>
            <div className='homepage__learn-more'>
                <Link to='/about'>
                    <button className='homepage__learn-more-btn'>
                        LEARN MORE
                        <AiOutlineArrowRight />
                    </button>
                </Link>
            </div>
        </section>
        <section className='homepage__gallery'>
            <div className='gallery-links__container'>
                <Link to='/gallery/food'>
                    <div className='gallery-links__card'>
                        <div className='gallery-links__card-overlay'></div>
                        <img className='gallery-links__card-image' src={foodImg} alt='food example'/>
                        <h3 className='gallery-links__card-title'>FOOD</h3>
                    </div>
                </Link>
                <Link to='/gallery/fitness'>
                    <div className='gallery-links__card gallery-links__card--low'>
                        <div className='gallery-links__card-overlay'></div>
                        <img className='gallery-links__card-image' src={fitnessImg}  alt='fitness example'/>
                        <h3 className='gallery-links__card-title'>FITNESS</h3>
                    </div>
                </Link>
                <Link to='/gallery/lifestyle'>
                    <div className='gallery-links__card'>
                        <div className='gallery-links__card-overlay'></div>
                        <img className='gallery-links__card-image' src={lifestyleImg} alt='lifestyle example'/>
                        <h3 className='gallery-links__card-title'>LIFESTYLE</h3>
                    </div>
                </Link>
                <Link to='/gallery/product'>
                    <div className='gallery-links__card gallery-links__card--low'>
                        <div className='gallery-links__card-overlay'></div>
                        <img className='gallery-links__card-image' src={productImg} alt='product example'/>
                        <h3 className='gallery-links__card-title'>PRODUCT</h3>
                    </div>
                </Link>
            </div>
            <Link to='/gallery'>
                <div className='gallery-links__cta'>
                    <h3 className='gallery-links__cta-text'>VIEW PORTFOLIO</h3>
                    <AiOutlineArrowRight />
                </div>
            </Link>
            <div className='homepage__gallery-title-container'>
                <h1 className='homepage__gallery-title'>SO, WHAT'S YOUR ENDEAVOUR?</h1>
                <div className='homepage__gallery-title-border'></div>
            </div>
        </section>
        <Footer />
    </>)
}

export default HomePage;