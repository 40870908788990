import { useState } from 'react';
import { BiErrorCircle as ErrorIcon } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import './Login.scss';

const { REACT_APP_LOGIN_NAME, REACT_APP_LOGIN_PASSWORD } = process.env;

const Login = ({ setLoggedIn }) => {
    const [loginForm, setLoginForm] = useState({
        username: "",
        password: ""
    });
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const navigate = useNavigate();

    const handleFormChange = (e) => {
        setLoginForm({...loginForm, [e.target.name]: e.target.value});
    }

    const handleLogin = e => {
        e.preventDefault();

        e.target[0].classList.remove('error-state');
        e.target[1].classList.remove('error-state');

        let emptyValue = false;
        if (e.target[0].value === '') { 
            e.target[0].classList.add('error-state');
            setUsernameError(true);
            emptyValue = true;
        } else {
            setUsernameError(false);
        }
        if (e.target[1].value === '') {
            e.target[1].classList.add('error-state');
            setPasswordError(true);
            emptyValue = true;
        } else {
            setPasswordError(false);
        }

        if (emptyValue) {
            return false;
        }

        if (loginForm.username === REACT_APP_LOGIN_NAME && loginForm.password === REACT_APP_LOGIN_PASSWORD) {
            setLoginForm({ username: "", password: "" });
            sessionStorage.setItem("loggedIn", "yessir");
            setLoggedIn(true);
            navigate("/admin/add-photos");
        } else {
            setLoginForm({ username: "", password: "" });
            window.alert('Incorrect login credentials');
        }
    }

    return (
        <form className='login-form__container' onSubmit={handleLogin}>
            <div className='login-form__username-container'>
                <h3 className='login-form__subtitle' >USER NAME</h3>
                <input className='login-form__username' name='username' placeholder='User name' value={loginForm.username} onChange={handleFormChange}></input>
                <div className={usernameError ? 'login-form__error' : 'login-form__hidden'}>
                    <ErrorIcon />
                    <p>This field is required</p>
                </div>
            </div>
            <div className='login-form__password-container'>
                <h3 className='login-form__subtitle' >PASSWORD</h3>
                <input className='login-form__password' name='password' placeholder='Password' type='password' value={loginForm.password} onChange={handleFormChange}></input>
                <div className={passwordError ? 'login-form__error' : 'login-form__hidden'}>
                    <ErrorIcon />
                    <p>This field is required</p>
                </div>
            </div>
            <div className='login-form__submit-container'>
                    <button type="submit" className='login-form__submit' >LOGIN</button>
            </div>
        </form>
    )
}

export default Login;