import { useState } from 'react';
import { BiErrorCircle as ErrorIcon } from 'react-icons/bi';
import './AddPhoto.scss';
import axios from 'axios';

const { REACT_APP_BACKEND_URL } = process.env;

const AddPhoto = () => {
    const [uploadImage, setUploadImage] = useState(null);
    const [photoForm, setPhotoForm] = useState({
        name: '',
        tag: ''
    })
    const [dimensions, setDimensions] = useState(null);


    const [nameError, setNameError] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [tagError, setTagError] = useState(false);

    const handlePublish = async (e) => {
        e.preventDefault();
        
        e.target[1].classList.remove('error-state');
        e.target[2].classList.remove('error-state');

        let emptyValue = false;
        if (!uploadImage) { 
            setImageError(true);
            emptyValue = true;
        } else {
            setImageError(false);
        }
        if (e.target[1].value === '') {
            e.target[1].classList.add('error-state');
            setNameError(true);
            emptyValue = true;
        } else {
            setNameError(false);
        }
        if (e.target[2].value === '') {
            e.target[2].classList.add('error-state');
            setTagError(true);
            emptyValue = true;
        } else {
            setTagError(false);
        }

        if (emptyValue) {
            return false;
        }
        
        
        const formData = new FormData();
        formData.append("image",  e.target[0].files[0], e.target[1].value);
        formData.append("name", e.target[1].value);
        formData.append("tags", e.target[2].value);
        formData.append("w", dimensions.width);
        formData.append("h", dimensions.height);

        if (window.confirm('Are you sure you want to upload?')) {
            
            await axios.post(`${REACT_APP_BACKEND_URL}photos/`, formData).then(res => {
                window.alert('image uploaded.');
                setUploadImage(null);
                setPhotoForm({name: '', tag: ''});
            }).catch(err => console.log(err));
            
        };
    }

    const handleFormChange = (e) => {
        setPhotoForm({...photoForm, [e.target.name]: e.target.value});
    }

    const handleImage = async (e) => {
        if (e.target.files[0]) {
            setUploadImage(e.target.files[0].name);
            setPhotoForm({...photoForm, name: e.target.files[0].name.slice(0, -4)});
            const reader = new FileReader();
            reader.onload = (() => {
            const img = new Image();
            img.src = reader.result;
                img.onload = function() {
                    setDimensions({
                        width: img.width,
                        height: img.height
                    });
                }
            })
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    return (<>
        <form className='add-photo__container' onSubmit={handlePublish}>
            <div className='add-photo__image-container'>
                <h3 className='add-photo__subtitle' >IMAGE</h3>
                <div className='add-photo__img-button-container'>
                    <label htmlFor='image-file' className='add-photo__image'>
                        {uploadImage !== null ? `${uploadImage}` : 'Select Image'}
                    </label>
                    <input className='add-photo__image-btn' name='image' id='image-file' type='file' accept="image/png, image/jpeg" onChange={handleImage}></input>
                </div>
                <div className={imageError ? 'add-photo__error' : 'add-photo__hidden'}>
                    <ErrorIcon />
                    <p>This field is required</p>
                </div>
            </div>
            <div className='add-photo__name-container'>
                <h3 className='add-photo__subtitle' >NAME</h3>
                <input className='add-photo__name' name='name' placeholder='Image Name' value={photoForm.name} onChange={handleFormChange}></input>
                <div className={nameError ? 'add-photo__error' : 'add-photo__hidden'}>
                    <ErrorIcon />
                    <p>This field is required</p>
                </div>
            </div>
            <div className='add-photo__tags-container'>
                <h3 className='add-photo__subtitle' >TAG</h3>
                <select className='add-photo__tag' name='tag' 
                value={photoForm.tag} onChange={handleFormChange}>
                    <option value="" disabled hidden>Please Select</option>
                    <option value='fitness'>Fitness</option>
                    <option value='food'>Food</option>
                    <option value='lifestyle'>Lifestyle</option>
                    <option value='product'>Product</option>
                </select>
                <div className={tagError ? 'add-photo__error' : 'add-photo__hidden'}>
                    <ErrorIcon />
                    <p>This field is required</p>
                </div>
            </div>
            <div className='add-photo__submit-container'>
                    <button type="submit" className='add-photo__submit' >UPLOAD</button>
            </div>
        </form>
    </>)
}

export default AddPhoto;