import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import Sidebar from '../../Components/Sidebar/Sidebar';
import { useState, useEffect } from 'react';
import './GalleryPage.scss';
import Gallery from '../../Components/Gallery/Gallery';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const { REACT_APP_BACKEND_URL } = process.env;

const GalleryPage = () => {
    const [dropdown, setDropdown] = useState(false);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const { category } = useParams();

    const toggleOpen = () => {
        setDropdown(!dropdown);
    }

    useEffect(() => {  
        let url = '';

        if (category) {
            url = `${REACT_APP_BACKEND_URL}photos/${category}`
        }
        else {
            url = `${REACT_APP_BACKEND_URL}photos/`
        }
        axios.get(url).then(res => {
            setImages(res.data);
            setLoading(false);
        }).catch(err => console.log(err));

        return () => {
            setLoading(true);
        }
    }, [category]);

    return (<>
        <Sidebar dropdown={dropdown} toggleOpen={toggleOpen} />
        <Header toggleOpen={toggleOpen} />
        <div className='gallery__container'>
            <Link to='/gallery' className='gallery__links'>ALL</Link>
            <Link to='/gallery/fitness' className='gallery__links'>FITNESS</Link>
            <Link to='/gallery/food' className='gallery__links'>FOOD</Link>
            <Link to='/gallery/product' className='gallery__links'>PRODUCT</Link>
            <Link to='/gallery/lifestyle' className='gallery__links'>LIFESTYLE</Link>
        </div>
        {loading ? (
            <div className="gallery__loading">
                <span className="loader"></span>
            </div>
        ) : (<Gallery images={images}/>)}
        <Footer />
    </>)
}

export default GalleryPage;