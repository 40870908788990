import { useEffect, useState } from 'react';
import axios from 'axios';
import './ManagePhotos.scss';
import { AiOutlineDelete } from 'react-icons/ai';

const { REACT_APP_BACKEND_URL } = process.env;

const ManagePhotos = () => {
  const [photoList, setPhotoList] = useState([]);
  const [loadingPhotos, setLoadingPhotos] = useState(true);

  useEffect(() => {
    axios.get(`${REACT_APP_BACKEND_URL}photos/`).then(res => {
      setPhotoList(res.data);
      setLoadingPhotos(false);
    }).catch(err => console.log(err));

  }, [])

  const updatePhotos = () => {
    axios.get(`${REACT_APP_BACKEND_URL}photos/`).then(res => {
      setPhotoList(res.data);
    }).catch(err => console.log(err));
  }

  const handleDelete = (e) => {
    if (window.confirm('Are you sure you want to delete this photo?')) {
      axios.delete(`${REACT_APP_BACKEND_URL}photos/${photoList[e.target.getAttribute('index')].name}`).then(_res => {
        updatePhotos();
      }).catch(err => console.log(err));
    }
  }

  const handleUpdate = (e) => {
    e.preventDefault();
    const updatedData = {
      topPick: e.target[1].value,
      tags: e.target[0].value
    }
    if (window.confirm('Are you sure you want to update this photo?')) {
      axios.put(`${REACT_APP_BACKEND_URL}photos/${e.target.getAttribute('index')}`, updatedData).then(_res => {
        updatePhotos();
      }).catch(err => console.log(err));
    }

  }

  const handleFormChange = e => {

    let newPhotoList = [...photoList];
    newPhotoList[e.target.getAttribute('index')][e.target.name]= e.target.value;
    setPhotoList(newPhotoList);

  }

  return (
    <section className='photos__top-container'>
      {loadingPhotos ? (<div className="photos__loading">
          <span className="loader"></span>
        </div>
        ) : (
        <div className='photos-loaded__container'>
          <div className='photos-title__container'>
            <p className='photos-title photos-title__photo'>PHOTO</p>
            <p className='photos-title photos-title__tags'>TAGS</p>
            <p className='photos-title photos-title__delete'>DELETE</p>
            <p className='photos-title photos-title__update'>UPDATE</p>
          </div>
          {photoList.map((photo, index) => (
            <form key={photo.id} index={photo.id} className='photos__container' onSubmit={handleUpdate}>
              <div className='photos__thumb-container'>
                <img className='photos__thumb' src={photo.thumb} alt=''/>
              </div>
              <div className='photos__tags-container'>
                <p className='photos__tags-title'>TAG</p>
                <select className='photos__tags' name='tag' value={photoList[index].tag ? photoList[index].tag : photoList[index].categories[0].tag} index={index} onChange={handleFormChange}>
                  <option value='fitness'>Fitness</option>
                  <option value='food'>Food</option>
                  <option value='lifestyle'>Lifestyle</option>
                  <option value='product'>Product</option>
                </select>
                <p className='photos__top-tag-title'>TOP TAG</p>
                <select className='photos__top-tag' name='topPick' value={photoList[index].topPick} index={index} onChange={handleFormChange}>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className='photos__delete'>
                <AiOutlineDelete />
                <div className='photos__delete-overlay' photo_id={photo.id} index={index} onClick={handleDelete}></div>
              </div>
              <div className='photos__update-container'>
                <button type="submit" className='photos__update'>UPDATE</button>
              </div>
            </form>
          ))}
        </div>)}
    </section>
  )
}

export default ManagePhotos;