import React from 'react'
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import Sidebar from '../../Components/Sidebar/Sidebar';
import { useState } from 'react';
import './ContactPage.scss';
import axios from 'axios';
import { BiErrorCircle as ErrorIcon } from 'react-icons/bi';
import { FaWindowClose } from 'react-icons/fa';

const ContactPage = () => {
    const { REACT_APP_BACKEND_URL } = process.env;
    const [dropdown, setDropdown] = useState(false);
    const [contactForm, setContactForm] = useState({
        fName: "",
        lName: "",
        email: "",
        message: ""
    });
    const [fNameError, setFNameError] = useState(false);
    const [lNameError, setLNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [submitModal, setSubmitModal] = useState(false);

    const handleSubmission = () => {
        if (!submitModal) {
          document.querySelector(".contact__modal-bg").style.display = "flex";
          document.querySelector(".contact__modal-container").style.display = "flex";
          setSubmitModal(true);
        } else {
          document.querySelector(".contact__modal-bg").style.display = "none";
          document.querySelector(".contact__modal-container").style.display = "none";
          setSubmitModal(false);
        }
    }

    const toggleOpen = () => {
        setDropdown(!dropdown);
    }

    const handleFormChange = e => {
        setContactForm({...contactForm, [e.target.name]: e.target.value});
    }

    const handleSave = async e => {
        e.preventDefault();

        e.target[0].classList.remove('error-state');
        e.target[1].classList.remove('error-state');
        e.target[2].classList.remove('error-state');
        e.target[3].classList.remove('error-state');

        let emptyValue = false;
        if (e.target[0].value === '') { 
            e.target[0].classList.add('error-state');
            setFNameError(true);
            emptyValue = true;
        } else {
            setFNameError(false);
        }
        if (e.target[1].value === '') {
            e.target[1].classList.add('error-state');
            setLNameError(true);
            emptyValue = true;
        } else {
            setLNameError(false);
        }
        if (e.target[2].value === '') {
            e.target[2].classList.add('error-state');
            setEmailError(true);
            emptyValue = true;
        } else {
            setEmailError(false);
        }
        if (e.target[3].value === '') {
            e.target[3].classList.add('error-state');
            setMessageError(true);
            emptyValue = true;
        } else {
            setMessageError(false);
        }

        if (emptyValue) {
            return false;
        }
        axios.post(`${REACT_APP_BACKEND_URL}contacts`, contactForm).then(_res => {
            setContactForm({
                fName: "",
                lName: "",
                email: "",
                message: ""
            });
            handleSubmission();
        }).catch(err => console.log(err));
    }

    return (<>
        <Sidebar dropdown={dropdown} toggleOpen={toggleOpen} />
        <Header toggleOpen={toggleOpen} />
        <section className='contact-container'>
            <div className="contact__modal-container">
                <div className='contact__modal-close' onClick={() => handleSubmission()}>
                    <FaWindowClose />
                </div>
                <div className="contact__modal-text">Thanks for submitting! Check your email for a little something extra from me.</div>
            </div>
            <div className="contact__modal-bg" onClick={() => handleSubmission()}></div>
            <div className='contact__hero-container'>
                <div className='contact__hero-image'></div>
                <div className='contact__hero-overlay'></div>
            </div>
            <div className='contact__title-container'>
                <h1 className='contact__title'>CONTACT</h1>
                <div className='contact__title-border'></div>
            </div>
            <form className='contact-form__container' onSubmit={handleSave}>
                <h3 className='contact-form__subtitle' >NAME</h3>
                <div className='contact-form__name-container'>
                    <div className='contact-form__name-container--left'>
                        <input className='contact-form__name' name='fName' placeholder='First Name' value={contactForm.fName} onChange={handleFormChange}></input>
                        <div className={fNameError ? 'contact-form__error' : 'contact-form__hidden'}>
                            <ErrorIcon />
                            <p>This field is required</p>
                        </div>
                    </div>
                    <div className='contact-form__name-container--right'>
                        <input className='contact-form__name' name='lName' placeholder='Last Name' value={contactForm.lName} onChange={handleFormChange}></input>
                        <div className={lNameError ? 'contact-form__error' : 'contact-form__hidden'}>
                            <ErrorIcon />
                            <p>This field is required</p>
                        </div>
                    </div>
                </div>
                <h3 className='contact-form__subtitle' >EMAIL</h3>
                <input className='contact-form__email' name='email' placeholder='Email' value={contactForm.email} onChange={handleFormChange}></input>
                <div className={emailError ? 'contact-form__error' : 'contact-form__hidden'}>
                    <ErrorIcon />
                    <p>This field is required</p>
                </div>
                <h3 className='contact-form__subtitle'>MESSAGE</h3>
                <textarea className='contact-form__message' name='message' placeholder='Let me know how we can work together!' value={contactForm.message} onChange={handleFormChange}></textarea>
                <div className={messageError ? 'contact-form__error' : 'contact-form__hidden'}>
                    <ErrorIcon />
                    <p>This field is required</p>
                </div>
                <div className='contact-form__submit-container'>
                    <button type="submit" className='contact-form__submit' >SUBMIT</button>
                </div>
            </form>
        </section>
        <Footer />
    </>)
}

export default ContactPage;