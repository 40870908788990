import React, { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import './Gallery.scss';
import Masonry from 'react-masonry-css';

const Gallery = ({ images }) => {

    useEffect(() => {
        const callback = (entries) => {   
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.style.animation = entry.target.dataset.animate;
                    observer.unobserve(entry.target);         
                } else {
                    entry.target.style.animation="none";
                }
            });       
        }
        let observer = new IntersectionObserver(callback);
        const animationItems = document.querySelectorAll('#animImg');
        animationItems.forEach(item => {
            observer.observe(item)         
        });

        let lightbox = new PhotoSwipeLightbox({
            gallery: '#img-gallery',
            children: 'a',
            bgOpacity: 0.9,
            padding: { top: 40, bottom: 40, left: 40, right: 40 },
            showHideAnimationType: 'fade',
            pswpModule: () => import('photoswipe'),
        });
        lightbox.init();
    
        return () => {
            lightbox.destroy();
            lightbox = null;
            observer.disconnect();
        };
    }, [images]);
    
    return (
        <Masonry
            breakpointCols={{default: 4, 1000: 3, 700: 2}}
            id="img-gallery"
            className="masonry-grid"
            columnClassName="masonry-grid_column">
            {images.map((image, index) => (
                <a
                href={image.link}
                id='animImg'
                data-animate="fadeUp 1s"
                data-pswp-width={image.width}
                data-pswp-height={image.height}
                key={image.id}
                target="_blank"
                rel="noreferrer"
                >
                <img src={image.thumb} alt="" />
                </a>
            ))}
        </Masonry>
    );
}

export default Gallery