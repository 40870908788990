import { useEffect } from 'react'
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Login from '../../Components/Login/Login';
import { Link, useParams } from 'react-router-dom';
import AddPhoto from '../../Components/AddPhoto/AddPhoto';
import ManageContacts from '../../Components/ManageContacts/ManageContacts';
import ManagePhotos from '../../Components/ManagePhotos/ManagePhotos';
import { useState } from 'react';
import './AdminPage.scss';



const AdminPage = () => {
    const [dropdown, setDropdown] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const { component } = useParams();
    const [displayedComponent, setDisplayedComponent] = useState(null);

    useEffect(() => {
        if(sessionStorage.getItem('loggedIn') === 'yessir') {
            setLoggedIn(true);
        }

        const componentToDisplay = () => {
            if (component) {
                const link = document.querySelectorAll('.admin__links');
                link.forEach(l => l.classList.remove('admin__selected'));
                if (component === 'add-photos') {
                    link[0].classList.add('admin__selected');
                    return (<AddPhoto />);    
                } else if (component === 'manage-photos') {
                    link[1].classList.add('admin__selected');
                    return (<ManagePhotos />);    
                } else if (component === 'manage-contacts') {
                    link[2].classList.add('admin__selected');
                    return (<ManageContacts />);    
                } else {
                    return (<></>);
                }
            } else if (!loggedIn) {
                return(<></>)
            }
            else {
                return (<div className='admin__logged-in'>
                    <p>Choose an option above.</p>
                </div>);
            }
        }

        setDisplayedComponent(componentToDisplay);

    }, [component, loggedIn]);

    const toggleOpen = () => {
        setDropdown(!dropdown);
    }

    return (<>
        <Sidebar dropdown={dropdown} toggleOpen={toggleOpen} />
        <Header toggleOpen={toggleOpen} />
        {!loggedIn ? (<Login setLoggedIn={setLoggedIn} />) : 
        (<div className='admin__container'>
            <Link to='/admin/add-photos' className='admin__links'>ADD PHOTOS</Link>
            <Link to='/admin/manage-photos' className='admin__links'>MANAGE PHOTOS</Link>
            <Link to='/admin/manage-contacts' className='admin__links'>MANAGE CONTACTS</Link>
        </div>)}
        {displayedComponent}
        <Footer />
    </>)
}

export default AdminPage;