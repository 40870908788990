import React from 'react'
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import Sidebar from '../../Components/Sidebar/Sidebar';
import { useState } from 'react';
import './StorePage.scss';

const StorePage = () => {
    const [dropdown, setDropdown] = useState(false);

    const toggleOpen = () => {
        setDropdown(!dropdown);
    }

    return (<>
        <Sidebar dropdown={dropdown} toggleOpen={toggleOpen} />
        <Header toggleOpen={toggleOpen} />
            <section className='store'>
                <div className='store__title-container'>
                    <h1 className='store__title'>COMING SOON</h1>
                    <div className='store__title-border'></div>
                </div>
                <div className='store__hero-container'>
                    <div className='store__hero'></div>
                    <div className='store__hero-overlay'></div>
                </div>
            </section>
        <Footer />
    </>)
}

export default StorePage;