import React, { useEffect } from 'react'
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import Sidebar from '../../Components/Sidebar/Sidebar';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import { useState } from 'react';
import './AboutPage.scss';

const AboutPage = () => {
    const [dropdown, setDropdown] = useState(false);
    const [offsetY, setOffsetY] = useState(0);
    const { height, width } = useWindowDimensions();
    const [parallaxOffset, setParallaxOffset] = useState(0);

    useEffect(() => {
        if (width < 768) {
            setParallaxOffset(-0.2);
        } else {
            setParallaxOffset(-0.5);
        }
    }, [width]);

    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        
        return () => window.removeEventListener('scroll', handleScroll );
    }, []);

    const toggleOpen = () => {
        setDropdown(!dropdown);
    }

    return (<>
        <Sidebar dropdown={dropdown} toggleOpen={toggleOpen} />
        <Header toggleOpen={toggleOpen} />
            <section className='about__top-container'>
                <div className='about__title-container'>
                    <h2 className='about__title'>ABOUT ME</h2>
                    <div className='about__title-border'></div>
                </div>
            </section>
            <section className='about-me__container'>
                <div className='about-me__background' style={{transform: `translateY(${offsetY * parallaxOffset}px)`}}></div>
                <div className='about-me__overlay'></div>
                <div className='about-me__overlay-wide'></div>
                <div className='about-me__text'>
                    <h3 className='about-me__subtitle'>LET'S GET TO KNOW EACH OTHER.</h3>
                    <p className='about-me__copy'>
                        I'll start.
                    </p>
                    <p className='about-me__copy'>
                        I'm Justin, and I'm a self-taught photographer based in Toronto, Canada.
                    </p>
                    <p className='about-me__copy'>
                        When I'm not taking or editing photos, you can find me coaching at my local gym or deep in a YouTube rabbit hole, fine tuning the latest skill that's caught my attention.
                    </p>
                </div>
            </section>
            <section className='about-subject__container' id='about-text-left'>
                <div className='about-subject__background' style={{transform: `translateY(${(offsetY) * parallaxOffset * 0.8}px)`}} id='fitness-image'></div>
                <div className='about-subject__overlay'></div>
                <div className='about-subject__overlay-wide'></div>
                <div className='about-subject__text'>
                    <p className='about-subject__copy'>
                        Some would say that I'm a nerd. Need an extra for trivia night? I'm your guy. Don't want to whip out a calculator but need fast math? Also your guy.
                    </p>
                </div>
            </section>
            <section className='about-subject__container' id='fitness-about'>
                <div className='about-subject__background' style={{transform: `translateY(${(offsetY - (0.2 * height))  * parallaxOffset * 0.65}px)`}} id='lifestyle-image'></div>
                <div className='about-subject__overlay'></div>
                <div className='about-subject__overlay-wide'></div>
                <div className='about-subject__text'>
                    <p className='about-subject__copy'>
                        My grandfather was my inspiration for getting into photography. I have one of his cameras—an Olympus Pen EE—sitting on my living room wall.
                    </p>
                </div>
            </section>
            <section className='about-subject__container' id='about-text-left'>
                <div className='about-subject__background' style={{transform: `translateY(${(offsetY - (0.7 * height)) * parallaxOffset * 0.65}px)`}} id='pet-image'></div>
                <div className='about-subject__overlay'></div>
                <div className='about-subject__overlay-wide'></div>
                <div className='about-subject__text'>
                    <p className='about-subject__copy'>
                        I share my home (and, begrudgingly, my bed) with two rescue dogs that I love—despite the bed thing. 
                    </p>
                </div>
            </section>
            <section className='about-subject__container' id='food-about'>
                <div className='about-subject__background' style={{transform: `translateY(${(offsetY - (1.1 * height)) * parallaxOffset * 0.6}px)`}} id='food-image'></div>
                <div className='about-subject__overlay'></div>
                <div className='about-subject__overlay-wide'></div>
                <div className='about-subject__text'>
                    <p className='about-subject__copy'>
                        I'm a big fan of incorporating elements of my favorite things into shoots, including all things food, architecture, fitness, and did I mention food?
                    </p>
                </div>
            </section>
        <Footer />
    </>)
}

export default AboutPage;