import { AiOutlineInstagram, AiOutlineMail } from 'react-icons/ai';
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
  return (
    <div className='footer__container'>
      <div className='footer__image'></div>
      <div className='footer__left-fade'></div>
      <div className='footer__top-fade'></div>
      <div className='footer__logo-container'>
        <Link to='/'>
          <h3 className='footer__logo'>NDVR</h3>
        </Link>
      </div>
      <div className='footer__text-container'>
        <p className='footer__text'>© 2022 NDVR PHOTOGRAPHY</p>
        <Link to='/admin'>
          <p className='footer__text footer__login'> ADMIN LOGIN</p>
        </Link>
      </div>
      <div className='footer__link-container'>
        <a href='https://www.instagram.com/ndvrphoto/'>
          <AiOutlineInstagram className='footer__link'/>
        </a>
        <a href='https://www.facebook.com/ndvrphoto/'>
          <FaFacebookSquare className='footer__link'/>
        </a>
        <a href='https://www.linkedin.com/in/justin--muir'>
          <FaLinkedin className='footer__link'/>
        </a>
        <a href='mailto:ndvphoto@gmail.com'>
          <AiOutlineMail className='footer__link'/>
        </a>
      </div>
    </div>
  )
}

export default Footer